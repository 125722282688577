<template>
  <div class="card card-custom">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">Добавление события</h3>
      </div>
    </div>
    <form>
      <div class="card-body">
        <BaseInput
          type="text"
          :label="'Название *'"
          :error="errors.get('title')"
          v-model="form.title"
        />

        <BaseTextarea
          rows="3"
          :label="'Краткое описание'"
          :error="errors.get('short_description')"
          v-model="form.short_description"
        />
        <editor
          v-model="form.text"
          maxlength="2000"
          placeholder="Не больше 2000 символов"
          rows="3"
          :api-key="apiKey"
          :init="{
            language: 'ru',
            toolbar:
              'undo redo | bold italic | alignleft aligncenter alignright alignjustify | numlist bullist checklist | link',
            plugins: 'image link advlist lists media',
          }"
        />

        <!-- <BaseInput
            type="date"
            class="col-3"
            :label="'Дата проведения'"
            :error="errors.get('published_at')"
            v-model="form.published_at"
        /> -->

        <div class="form-group mt-5">
          <div class="checkbox-list">
            <label class="checkbox">
              <input type="checkbox" v-model="form.active" />
              <span></span>Активно</label
            >
          </div>
        </div>

        <br />
        <h3>Seo</h3>

        <BaseInput
          type="text"
          :label="'SEO title'"
          :error="errors.get('seo_title')"
          v-model="form.seo_title"
        />

        <BaseTextarea
          rows="3"
          :label="'SEO description'"
          :error="errors.get('seo_description')"
          v-model="form.seo_description"
        />
      </div>
      <div class="card-footer">
        <BaseButton @click.prevent="save()">Сохранить</BaseButton>
        <BaseButton btnClass="btn-secondary" @click.prevent="$emit('close')"
          >Отменить</BaseButton
        >
      </div>
    </form>
  </div>
</template>

<script>
import { Errors } from "@/classes/Errors.js";
import Editor from "@tinymce/tinymce-vue";

export default {
  props: {
    entity: Object,
  },
  components: { Editor },

  data() {
    return {
      apiKey: process.env.VUE_APP_TINIMCE_KEY,
      errors: new Errors(),
      dialogVisible: false,
      form: this.initForm(),
    };
  },

  methods: {
    initForm() {
      return {
        image: null,
        title: null,
        short_description: null,
        text: null,
        active: false,
        seo_title: null,
        seo_description: null,
      };
    },
    save() {
      if (this.isProcess) {
        return;
      }

      this.isProcess = true;

      this.form["active"] = Number(this.form.active);

      let formData = new FormData();

      for (let key in this.form) {
        if (this.form[key] != null) {
          formData.set(key, this.form[key]);
        }
      }

      formData.set("action", this.form["action"]);

      if (this.entity) {
        formData.set("_method", "PUT");
      }

      const $url = this.entity ? `/events/${this.entity.id}` : "/events";

      axios
        .post($url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.isProcess = false;

          this.$notify({
            type: "success",
            message: "Книга добавлена!",
          });
          this.errors.clearAll();

          this.$emit("close");
          this.$emit("onUpdated");
        })
        .catch((error) => {
          this.isProcess = false;

          if (error.response && error.response.status == 422) {
            this.$notify({
              type: "warning",
              message: error.response.data.message,
            });
            this.errors.set(error.response.data.errors);
          }

          if (error.response && error.response.status == 403) {
            this.$notify({
              type: "warning",
              message: error.response.data.message,
            });
          }
        });
    },

    loadEntity(id) {
      axios
        .get(`/events/${id}`)
        .then((res) => {
          this.form = Object.assign({}, this.initForm(), res.data);
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            message: "Не удалось загрузить данные",
          });
        });
    },
  },

  created() {
    if (this.entity) {
      this.loadEntity(this.entity.id);
    }
  },
};
</script>

<style>
.avatar {
  width: 100%;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 278px;
  line-height: 178px;
  text-align: center;
}
</style>
